.ant-radio-group-solid label {
  padding: 5px 10px;
  height: auto;
}

.ant-radio-group-solid label:hover {
  color: rgba(0, 0, 0, 0.65);
}

.ant-radio-group-solid label:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.ant-radio-group-solid label:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #051a55;
  border: none;
  box-shadow: none;
  outline: 0;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #051a55;
  border-color: #051a55;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #051a55;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: " ";
}
tr.ant-table-row-selected:hover {
  background: #f3f6fb !important;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #f3f6fb;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-item,
.ant-pagination-item:focus,
.ant-pagination-item a,
.ant-pagination-item a:hover,
.ant-pagination-item a:focus,
.ant-pagination-item a:visited,
.ant-pagination-item a:checked {
  color: #585858;
  font-weight: 500;
  background-color: #f3f6fb;
  border: none;
}

.ant-pagination-item-active,
.ant-pagination-item-active:focus,
.ant-pagination-item-active::selection,
.ant-pagination-item-active a,
.ant-pagination-item-active a:hover,
.ant-pagination-item-active a:focus,
.ant-pagination-item-active a:visited,
.ant-pagination-item-active a:checked {
  color: white !important;
  font-weight: 500;
  background-color: #1645b8;
  border: none;
  border-radius: 5px;
}
